<template>
    <div class="newsletter-body">
        <template v-for="(el,key) in json.blocks" >
            <div v-if="el" :key="key" class="contenedor-texto">
                <!-- Paragraph -->
                <div v-if="el.type == 'paragraph'" class="paragraph">
                    <p v-html="el.data.text"></p>
                </div>

                <!-- Image -->
                <template v-else-if="el.type == 'image'">
                    <div class="image-container">
                        <img :src="el.data.file.url" class="img-fluid" :alt="el.data.caption" :title="el.data.caption" />
                        <span v-html="el.data.caption" class="pie"></span>
                    </div>
                </template>

                <!-- Header -->
                <h4 :nivel="el.data.level" v-else-if="el.type == 'header'" class="destacado" v-html="el.data.text"></h4>

                <!-- Embed -->
                <div class="container-embed" v-if="el.type == 'embed'">
                    <iframe :src="el.data.embed" :data-service="el.data.service"></iframe>
                    <span>{{ el.data.caption }}</span>
                </div>

                <!-- File -->
                <div class="file row-center" v-else-if="el.type == 'attaches'">
                    <a download :href="el.data.file.url" class="url-download" rel="noopener" title="url-download" target="_blank">
                        Descargar {{ el.data.title }}</a>
                </div>

                <!-- Slider -->
                <div class="container-gallery" v-else-if="el.type == 'gallery'">
                    <p class="title-dest-underline w-100">Galería</p>
                    <agile :options="AgileOptions" ref="carousel">
                        <div class="slide" v-for="(img, key) in el.data.images" :key="key">
                            <img style="max-width: 100%;height: auto;" :src="img.file.url" :alt="img.caption" :title="img.caption" />
                        </div>
                    </agile>
                </div>

                <!-- Prety Link -->
                <div class="preaty-link row-center" v-if="el.type == 'linkTool'">
                    <div class="link row-start">
                        <a :href="el.data.link" class="" title="data link" rel="noopener" target="_blank"></a>

                        <img v-if="!!el.data.meta.image" :src="el.data.meta.image.url" :alt="el.data.meta.image_footer" />
                        <h2>{{ el.data.meta.title }}</h2>
                        <p>{{ el.data.meta.description }}</p>
                    </div>
                </div>

                <!-- List -->
                <section v-else-if="el.type == 'list' || el.type == 'nestedList'">
                    <template v-if="el.data.style == 'ordered'">
                        <ol>
                            <listComponent v-for="(item, index) in Object.assign({}, el.data.items)" :key="index" :item="item" :listStyle="el.data.style" />
                        </ol>
                    </template>
                    <template v-else>
                        <ul>
                            <listComponent v-for="(item, index) in Object.assign({}, el.data.items)" :key="index" :item="item" :listStyle="el.data.style" />
                        </ul>
                    </template>
                </section>

                <!-- Boton -->
                <div class="button row-center" v-else-if="el.type == 'button'">
                    <a target="_blank" :href="el.data.link" class="btn -skull-blue" title="data link">{{
                        el.data.text
                        }}</a>
                </div>

                <!-- Tabla -->
                <div class="table-container row-center" v-else-if="el.type == 'table'">
                    <table>
                        <tr v-for="(td, tdkey) in el.data.content" :key="tdkey">
                            <td :key="ekey" v-for="(e, ekey) in td" v-html="e"></td>
                        </tr>
                    </table>
                </div>

                <!-- Columnas -->
                <div class="columns d-flex-columns" v-else-if="el.type == 'columns'">
                    <div class="column" v-for="(el, subkey) in el.data.itemContent" :key="subkey">
                        <editorjs :object="el"></editorjs>
                    </div>
                </div>

                <!-- Raw -->
                <div v-else class="raw">
                    <div v-html="el.data.html"></div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import {
        VueAgile
    } from "vue-agile";
    import listComponent from "@/components/list-component.vue";
    export default {
        name: "editorjs",
        props: ["object"],
        computed: {
            json() {
                if (typeof this.object === "string" || this.object instanceof String) {
                    return JSON.parse(this.object);
                }
                return this.object;
            },
        },
        data: () => ({
            AgileOptions: {
                autoplay:true,
                responsive: [{
                    breakpoint: 900,
                    settings: {
                        navButtons: false,
                        slidesToShow: 3,
                        dots: false,
                    },
                }, 
                {
                    breakpoint: 600,
                    settings: {
                        navButtons: false,
                        slidesToShow: 2,
                        dots: false,
                    },
                }, {
                    breakpoint: 0,
                    settings: {
                        navButtons: false,
                        slidesToShow: 1,
                        dots: false,
                    },
                },],
            },
        }),

        components: {
            listComponent,
            agile: VueAgile
        },
        filters: {},
    };
</script>
<style>
    .image-container,
    .container-embed{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
    }
</style>