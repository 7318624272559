<template>
  <div class="wrapper -ficha-noticia padding-menu">
    <div class="container ficha-noticia" v-if="entry !== null">
      <h3>{{ $t('projects_rsc.title') }}</h3>
      <router-link :to="'/' + $t('paths.proyectos-rsc')" class="volver">
        {{ $t('project_rsc_details.btn_back') }}
      </router-link>
      <div class="intro-noticia row-between ais">
        <div class="texto">
          <div>
            <h1>{{ entry.name }}</h1>
            <p><strong v-html="entry.header"></strong></p>
          </div>
        </div>
        <div class="imagen-principal">
          <img :src="entry.image.url" :alt="entry.name" />
          <p v-if="entry.header_img_subtitle" class="entradilla" v-html="entry.header_img_subtitle"></p>
        </div>
      </div>
      <editorjs :object="entry.body"></editorjs>
      <div class="rrss row-start">
        <h4>{{ $t('project_rsc_details.share') }}</h4>
        <a class="linkedin" target="_blank" :href="`https://www.linkedin.com/shareArticle?mini=false&url=${url}&title=${entry.title}`
          "><i class="fa fa-linkedin"></i></a>
        <a class="twitter" target="_blank" :href="`http://twitter.com/intent/tweet?text=${entry.title}&url=${url}`
          "><img style="height: 19px; margin-top: 5px;" src="/img/icons/icon-twitter-new.svg" /></a>
        <a class="facebook" target="_blank" :href="`http://www.facebook.com/sharer.php?u=${url}&t=${entry.title}`"><i
            class="fa fa-facebook"></i></a>
      </div>

      <section class="contenedor-proyectos" v-if="entries !== null">
        <p class="title">{{ $t('project_rsc_details.tips') }}</p>
        <div class="row-start container">
          <template v-for="(n, key, index) in entries">
            <proyecto v-if="index < 3 & (entry.id != n.id)" :key="key" :data="n"></proyecto>
          </template>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import proyecto from "@/components/proyecto-rsc.vue";
import editorjs from "@/components/editorjs";

export default {
  name: "ficha-noticia",
  mounted() {
    this.getData();
  },
  data() {
    return {
      entry: null,
      entries: null,
    };
  },
  methods: {
    ...mapActions(["requestEntrie", "requestEntries"]),
    getData() {
      this.requestEntrie(this.$route.params.id).then((r) => {
        if (r) {
          this.entry = r;
          this.getEntries();
          // document.title = (this.data.seoTitle) ? this.data.seoTitle : this.data.titulo;
          // document.head.querySelector("[name=description]").content = (this.data.seoDescription) ? this.data.seoDescription : this.data.entradilla.split(0, 100);

          // if (this.data.img.crop) {
          //     document.head.querySelector("[property='og:image']").content = this.data.img.crop;
          //     document.head.querySelector("[name='twitter:image']").content = this.data.img.crop;
          // }

          // document.head.querySelector("[property='og:title']").content = (!this.data.seoTitle) ? this.data.seoTitle : this.data.titulo;
          // document.head.querySelector("[name='twitter:title']").content = (this.data.seoTitle) ? this.data.seoTitle : this.data.titulo
          // document.head.querySelector("[name='twitter:site']").content = location.href;
          // document.head.querySelector("[property='og:description']").content = (this.data.seoDescription) ? this.data.seoDescription : this.data.entradilla.split(0, 100);
          // document.head.querySelector("[name='twitter:description']").content = (this.data.seoDescription) ? this.data.seoDescription : this.data.entradilla.split(0, 100);
        }
      });
    },
    getEntries() {
      if (this.entry) {
        this.requestEntries({
          filter: {
            search: "",
            category: this.entry.category[0].id,
            lang: parseInt(this.$t(`langs.${this.$route.params.lang}`))
          },
        });
        setTimeout(() => {
          this.entries = this.rscEntries(1);
        }, "1000");
      }
    },
  },
  computed: {
    ...mapGetters({
      rscEntries: "getEntriesRsc",
    }),
    url() {
      return location.href;
    },
  },
  components: {
    editorjs: editorjs,
    proyecto: proyecto,
  },
  filters: {
    urlToID(value) {
      let string;

      if (value.includes("youtube")) {
        string = "https://www.youtube.com/embed/" + value.split("?v=")[1];
      } else if (value.includes("vimeo")) {
        string = "https://player.vimeo.com/video/" + value.split("/").pop();
      }

      return string;
    },
  },
  watch: {
    "$route.path"() {
      this.getData();
    },
  },
};
</script>
